<template>
  <div
    v-loading.fullscreen.lock="loading"
    element-loading-text="加载中..."
    class="w-common-list-wrap"
  >
    <div class="w-crumbs-href">
      <router-link
        tag="span"
        :to="{ path: '/' }"
      >工作台</router-link>
      <i class="el-icon-arrow-right"></i>
      <!-- <router-link
        tag="span"
        :to="{ path: '/' }"
      >首页</router-link>
      <i class="el-icon-arrow-right"></i> -->
      <span class="w-white">常用功能管理</span>
    </div>
    <div class="w-used-func-wrap">
      <div
        class="w-used-left fl"
        :style="{height: height + 'px'}"
      >
        <span class="w-used-title">已添加的常用功能</span>
        <p class="w-used-tips"> 点击 <span class="icon-delete"></span> 可取消对应功能在常用功能区显示 </p>
        <div class="w-used-list">
          <div
            class="w-used-item"
            v-for="item in oftenList"
            :key="item.id"
            @click="toPage(item)"
          >
            <div
              class="w-is-has-used"
              v-if="item.cnname2"
            >
              <i
                class="icon-delete"
                @click.stop="deleteConfig(item)"
              ></i>
              <p class="w-used-title"><span :class="['icon-' + item.sign]"></span>{{item.cnname2}}</p>
              <p class="w-used-tips">{{item.remark}}</p>
            </div>
            <div
              class="w-is-not-used"
              v-else
            >
            </div>
          </div>
        </div>
      </div>
      <div
        class="w-used-right fl"
        ref="elementHeight"
      >
        <span class="w-used-title">所有功能</span>
        <p class="w-used-tips"> 点击 <span class="icon-add"></span> 可添加对应功能在常用功能区显示 </p>
        <div class="w-all-func-wrap">
          <div
            class="w-all-func-item"
            v-for="item in configList"
            :key="item.id"
          >
            <h3>{{item.name}}</h3>
            <div
              class="w-used-item"
              v-for="list in item.dicVOS"
              :key="list.id"
              @click="toPage(list)"
            >
              <div class="w-is-has-used">
                <i
                  class="icon-add"
                  @click.stop="addUserConfig(item, list)"
                ></i>
                <p class="w-used-title"><span :class="['icon-' + list.sign]"></span>{{list.name}}</p>
                <p class="w-used-tips">{{list.remark}}</p>
              </div>
            </div>
            <div class="clearfix"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="clearfix"></div>
  </div>
</template>

<script>
import api from '@/api'
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      loading: true,
      oftenList: [],
      configList: [],
      height: 800
    }
  },
  computed: {
    ...mapGetters({
      token: 'token'
    }),
    url () {
      let temUrl = ''
      if (process.env.NODE_ENV === 'development') {
        temUrl = 'http://' + document.domain + ':8080'
      }
      return temUrl
    }
  },
  mounted () {
    this.getUserConfigListFunc()
    this.getOftenConfigFunc()
    this.$nextTick(() => {
      setTimeout(() => {
        this.height = this.$refs.elementHeight.offsetHeight - 20
      }, 1000)
    })
  },
  methods: {
    isIE () { // ie?
      if (!!window.ActiveXObject || 'ActiveXObject' in window) { return true } else { return false }
    },
    toPage (item) {
      localStorage.removeItem('saveScreen')
      if (item.sign === 'zsgh_xmzt') {
        window.location.href = this.url + '/#/work'
      } else if (item.sign === 'zsgh_zsmb') {
        window.location.href = this.url + '/#/investmentAimMange'
      } else if (item.sign === 'zsgh_pwzs') {
        window.location.href = this.url + '/#/berthInvestmentManage'
      } else if (item.sign === 'zsgh_pwch') {
        window.location.href = this.url + '/#/detachingShops'
      } else if (item.sign === 'zsss_zsgj') {
        window.location.href = this.url + '/#/implement'
      } else if (item.sign === 'zsss_spqy') {
        window.location.href = this.url + '/#/shopsSinged'
      } else if (item.sign === 'zsss_zsbg') {
        window.location.href = this.url + '/#/merchantReports'
      } else if (item.sign === 'zsgh_ghfx') {
        window.location.href = this.url + '/#/investmentPlanAnalysis?type=1'
      } else if (item.sign === 'zsss_ssfx') {
        window.location.href = this.url + '/#/implementationAnalysis?type=1'
      } else if (item.sign === 'zsss_jdgk') {
        window.location.href = this.url + '/#/implement'
      } else if (item.sign === 'zsss_ksgl') {
        window.location.href = this.url + '/#/visualization'
      } else if (item.sign === 'zsss_pwzs') {
        window.location.href = this.url + '/#/berthInvestmentManage'
      } else if (item.sign === 'zsss_ppzs') {
        window.location.href = this.url + '/#/investmentFollowManage'
      } else if (item.sign === 'zsss_zsjb') {
        window.location.href = this.url + '/#/merchantReports'
      } else {
        window.location.href = item.url + '&tokenval=' + this.token
      }
      if (document.domain.indexOf('local') !== -1 || document.domain.indexOf('prewbms') !== -1) {
        if (this.isIE()) {
          window.location.reload(true)
        }
      }
    },
    addUserConfig (item, list) {
      if (this.oftenList.length === 6) {
        this.$message({
          type: 'warning',
          message: '最多只能添加六个常用功能!'
        })
        return false
      }
      this.loading = true
      const params = {
        cnname1: item.name,
        id1: item.id,
        cnname2: list.name,
        id2: list.id
      }
      this.axios.post(api.setUserConfig, params)
        .then((res) => {
          this.$message({
            type: 'success',
            message: '添加成功!'
          })
          this.getOftenConfigFunc()
          this.getUserConfigListFunc()
        })
    },
    deleteConfig (item) {
      this.loading = true
      const params = {
        id: item.id
      }
      this.axios.post(api.deleteUserConfig, params)
        .then((res) => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
          this.getOftenConfigFunc()
          this.getUserConfigListFunc()
        })
    },
    getOftenConfigFunc () {
      this.axios.post(api.getUserOftenConfigList)
        .then((res) => {
          this.oftenList = res.data.data
        })
    },
    getUserConfigListFunc () {
      this.axios.post(api.getUserConfigList)
        .then((res) => {
          this.configList = res.data.data
          this.loading = false
        })
    }
  }
}
</script>

<style lang="stylus" scoped>
.w-common-list-wrap
  padding-bottom 25px
  .w-crumbs-href
    height 40px
    line-height 40px
    padding-left 25px
    span
      color #666
      font-size 14px
      cursor pointer
    i
      color #666
      margin 0 5px
  .w-used-left
    width 540px
    background #272930
    box-shadow 0px 1px 12px 0px rgba(0, 0, 0, 0.31)
    border-radius 3px
    padding-top 20px
    .w-used-title
      font-size 16px
      font-weight 500
      color #FFFFFF
      margin-left 16px
    .w-used-tips
      font-size 12px
      font-weight 400
      color rgba(255, 255, 255, 0.5)
      margin-left 16px
      margin-top 5px
      span
        color #FFA134
    .w-used-list
      margin-top 20px
      .w-used-item
        width 38%
        float left
        cursor pointer
        margin 20px 30px
        border-radius 3px
        &:hover .w-used-title
          color #FFA134
        .w-is-has-used
          min-height 68px
          position relative
          color #fff
          border 1px dashed rgba(255, 255, 255, 0.5)
          i
            position absolute
            right -1px
            top -1px
            font-size 12px
            color #FFA134
            font-weight bolder
          .w-used-title
            margin-top 10px
            span
              font-size 20px
              vertical-align middle
              margin-right 8px
          .w-used-tips
            margin-left 42px
            width 159px
            height 12px
            font-size 12px
            font-weight 400
            color #A5A5A7
        .w-is-not-used
          min-height 68px
          line-height 68px
          border 1px dashed #FFFFFF
          text-align center
          opacity 0.2
          border-radius 3px
          font-size 14px
          font-weight 400
          color #A5A5A7
  .w-used-right
    width calc(100% - 570px)
    background #272930
    box-shadow 0px 1px 12px 0px rgba(0, 0, 0, 0.31)
    border-radius 3px
    padding-top 20px
    margin-left 24px
    .w-used-title
      font-size 16px
      font-weight 500
      color #FFFFFF
      margin-left 16px
    >.w-used-tips
      font-size 12px
      font-weight 400
      color rgba(255, 255, 255, 0.5)
      margin-left 16px
      margin-top 5px
      margin-bottom 36px
      span
        color #000
        font-size 12px
        color #0FC967
        font-weight bolder
    .w-all-func-wrap
      .w-all-func-item
        h3
          width 232px
          height 28px
          line-height 28px
          background linear-gradient(to right, rgba(255, 161, 52, 0.3), rgba(255, 255, 255, 0))
          padding-left 12px
          font-size 16px
          font-weight 500
          color #FFFFFF
        .w-used-item
          width 210px
          float left
          cursor pointer
          margin 30px 10px 30px 30px
          border-radius 3px
          &:hover .w-used-title
            color #FFA134
            span
              color #FFA134
          .w-is-has-used
            min-height 68px
            position relative
            color #fff
            border 1px dashed rgba(255, 255, 255, 0.5)
            i
              position absolute
              right -1px
              top -1px
              color #000
              font-size 12px
              color #0FC967
              font-weight bolder
            .w-used-title
              margin-top 10px
              span
                font-size 20px
                vertical-align middle
                margin-right 8px
            .w-used-tips
              margin-left 42px
              width 159px
              height 12px
              font-size 12px
              font-weight 400
              color #A5A5A7
</style>
